import "./Dashboard.css";
import { useCallback, useRef, useState } from "react";
import { useApi } from "../ApiProvider/ApiProvider";
import { useAppState } from "../AppStateProvider/AppStateProvider";
import { useEffect } from "react";
import { Loading } from "../Loading/Loading";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { v4 as uuidv4 } from "uuid";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ComposedChart,
  CartesianGrid,
  Area,
  PieChart,
  Pie,
  Cell,
  Text,
  BarChart,
} from "recharts";
import { useGenerateImage } from "recharts-to-png";
import { jsPDF } from 'jspdf';
// import FileSaver from "file-saver";

// Error log override for recharts
const error = console.error;
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return;
  error(...args);
};

// Select data for frames
const frames = ["sensor1", "sensor2", "sensor3", "sensor4", "sensor5"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// This component manages Top Ten visuals
export function TopTenDashboard(props) {
  // Destruct props
  const { admin } = props;

  // Get the analytics api
  const { analyticsApi } = useApi();

  // Get notification context
  const { setToast } = useAppState();

  // Loading state
  const [loading, setLoading] = useState(false);

  // Unique site IDs
  const [sites, setSites] = useState([]);

  // From and to date filters
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  // Filtered site names
  const [siteFilter, setSiteFilter] = useState([]);
  // Filtered frames names
  const [frameFilter, setFrameFilter] = useState([...frames]);

  // Flag to apply the filters and fetch the corresponding data
  const [applyFilters, setApplyFilters] = useState(false);

  // Interaction data
  const [interactions, setInteractions] = useState([]);

  // Fetch site data from the server
  const fetchSites = useCallback(async () => {
    try {
      // Show the loading indicator
      setLoading(true);

      // Get Top Ten sites
      const res = await analyticsApi.topTenSites();
      if (!res || !res.Data || !res.Data.Count || !res.Data.Records)
        throw new Error("invalid or empty site data");

      // Set valid site IDs
      setSites(res.Data.Records || []);
      setSiteFilter(res.Data.Records.map(site => site.SiteId) || [])

    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }

    // Hide the loading indicator and reset the apply filter flag
    setLoading(false);
    setApplyFilters(false);
  }, [setToast, analyticsApi]);

  // Fetch site data on-mount
  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  // Fetch interaction data from the server
  const fetchInteractions = useCallback(async () => {
    try {
      // Show the loading indicator
      setLoading(true);

      // Get Top Ten interactions
      const res = await analyticsApi.topTenInteractions(
        dayjs(fromDate).format("YYYY-MM-DD"),
        dayjs(toDate).format("YYYY-MM-DD"),
        siteFilter,
        frameFilter,
        -1,
        -1,
        admin ? 1 : 0
      );
      // Exit if invalid
      if (!res || !res.Data || !res.Data.Count || !res.Data.Records) {
        setInteractions([]);
        throw new Error("invalid or empty interaction data");
      }

      // Set interaction data for the visuals
      setInteractions(res.Data.Records.reverse());
    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }

    // Hide the loading indicator and reset the apply filter flag
    setLoading(false);
    setApplyFilters(false);
  }, [
    setToast,
    analyticsApi,
    fromDate,
    toDate,
    siteFilter,
    frameFilter,
    admin,
  ]);

  // Run this effect when the "apply filters" flag has been set 'true'
  useEffect(() => {
    // Exit if the apply filters flag is false
    if (!applyFilters)
      return setApplyFilters(false);

    // Exit if filtered frames are empty
    if (frameFilter.length < 1) {
      setToast({
        message: "You must select atleast 1 frame to query",
        type: "error", // success / error / warning / info / default
      });
      return setApplyFilters(false);
    }

    // Exit if filtered sites are empty
    if (siteFilter.length < 1) {
      setToast({
        message: "You must select atleast 1 site to query",
        type: "error", // success / error / warning / info / default
      });
      return setApplyFilters(false);
    }

    // Exit if the to date is before the from date
    if (toDate.isBefore(fromDate)) {
      setToast({
        message: "From date cannot be after to date",
        type: "error", // success / error / warning / info / default
      });
      return setApplyFilters(false);
    }

    // If all checks pass, fetch the data
    fetchInteractions();

  }, [fetchInteractions, applyFilters, frameFilter, siteFilter, toDate, fromDate, setToast]);

  // Reset filters to their initial state
  const clearFilters = () => {
    setFromDate(dayjs());
    setToDate(dayjs());
    setSiteFilter([]);
    setFrameFilter([]);
  };

  // Handle a change in a multiselect filter
  const handleSelectFilterChange = (event, setter) => {
    const {
      target: { value },
    } = event;
    setter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // Implement useGenerateImage to get an image of any element (not just a Recharts component)
  const [getDashboardImage, { ref: exportRef }] = useGenerateImage({
    quality: 1,
    type: "image/png",
  });
  const handleDashboardDownload = useCallback(async () => {
    const image = await getDashboardImage();
    if (!image) return;
    // FileSaver.saveAs(image, "topten-dashboard.png");
    var doc = new jsPDF();
    doc.addImage(image, 'PNG', 0, 0, 210, 297);
    // doc.addImage(image, 'PNG', 0, 0, 200, 261);
    doc.save("topten-dashboard.pdf");
  }, [getDashboardImage]);

  // Callback used to update the export element ref
  const setExportRef = useCallback(
    (r) => (exportRef.current = r.current),
    [exportRef]
  );

  // Flush dialog open/close state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // Delete the filtered interactions
  const onDeleteClick = () => {
    setDeleteDialogOpen(admin && true);
  };

  // Restore any currently filtered interactions
  const onRestoreClick = async () => {
    try {
      // Show the loading indicator
      setLoading(true);

      // Get Top Ten interactions if an admin
      if (!admin) return;
      await analyticsApi.restoreTopTenInteractions(
        dayjs(fromDate).format("YYYY-MM-DD"),
        dayjs(toDate).format("YYYY-MM-DD"),
        siteFilter,
        frameFilter
      );
    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }

    // Hide the loading indicator
    setLoading(false);
    // Refresh the data
    setApplyFilters(true);
  };

  // Handle a delete confirmation, deleting the filtered data
  const onDeleteConfirm = async () => {
    try {
      // Show the loading indicator
      setLoading(true);

      // Get Top Ten interactions if an admin
      if (!admin) return;
      await analyticsApi.deleteTopTenInteractions(
        dayjs(fromDate).format("YYYY-MM-DD"),
        dayjs(toDate).format("YYYY-MM-DD"),
        siteFilter,
        frameFilter
      );
    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }

    // Hide the loading indicator
    setLoading(false);
    // Close the dialog
    setDeleteDialogOpen(false);
    // Refresh the data
    setApplyFilters(true);
  };

  // Dialog close handler
  const onDeleteCancel = (event, reason) => {
    // Do not close for background clicks
    if (reason === "backdropClick") return;
    // Close the dialog
    setDeleteDialogOpen(false);
  };

  // Get the elements to render
  const getElements = () => {
    return (
      <div className="container">
        <div className="container flex-column">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginTop={2}
            marginBottom={2}
            spacing={2}
            padding={1}
            style={{
              borderBottom: "1px solid #ffffff44",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleDashboardDownload}
              size="medium"
            >
              Export
            </Button>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From date..."
                value={fromDate}
                onChange={(v) => setFromDate(v)}
              />
              <DatePicker
                label="To date..."
                value={toDate}
                onChange={(v) => setToDate(v)}
              />
            </LocalizationProvider>

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="site-filter-label">Sites</InputLabel>
              <Select
                labelId="site-filter-label"
                id="site-filter"
                multiple
                value={siteFilter}
                onChange={(e) => handleSelectFilterChange(e, setSiteFilter)}
                input={<OutlinedInput label="Sites" />}
                renderValue={(selected) => {
                  var siteNames = [];
                  selected.forEach((id) => {
                    const x = sites.find((s) => s.SiteId === id);
                    if (x?.SiteName) siteNames.push(x.SiteName);
                  });
                  return siteNames.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {sites.map((site) => (
                  <MenuItem key={site.SiteId} value={site.SiteId}>
                    <Checkbox checked={siteFilter.indexOf(site.SiteId) > -1} />
                    <ListItemText primary={site.SiteName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="frame-filter-label">Frames</InputLabel>
              <Select
                labelId="frame-filter-label"
                id="frame-filter"
                multiple
                value={frameFilter}
                onChange={(e) => handleSelectFilterChange(e, setFrameFilter)}
                input={<OutlinedInput label="Frames" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {frames.map((frame) => (
                  <MenuItem key={frame} value={frame}>
                    <Checkbox checked={frameFilter.indexOf(frame) > -1} />
                    <ListItemText primary={frame} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="outlined"
              color="success"
              onClick={() => setApplyFilters(true)}
              size="medium"
            >
              Apply
            </Button>

            <Button
              variant="outlined"
              onClick={() => clearFilters()}
              size="medium"
            >
              Clear
            </Button>

            {admin && (
              <>
                <Button
                  variant="outlined"
                  onClick={onDeleteClick}
                  size="medium"
                  color="error"
                >
                  Delete
                </Button>
                <Button
                  variant="outlined"
                  onClick={onRestoreClick}
                  size="medium"
                >
                  Restore
                </Button></>
            )}
          </Stack>
          {admin && (
            <Dialog
              open={deleteDialogOpen}
              onClose={onDeleteCancel}
              sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 750 } }}
              disableEscapeKeyDown
            >
              <DialogTitle>
                <Typography
                  sx={{ mt: 4, mb: 2 }}
                  variant="body"
                  color={"error"}
                >
                  This action is irreversible
                </Typography>
              </DialogTitle>
              <DialogContent dividers>
                <Typography sx={{ mt: 4, mb: 2 }} variant="body1">
                  Deleting data cannot be undone and may have severe
                  side-effects in production. Are you sure you want to proceed?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={onDeleteCancel}>Close</Button>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={onDeleteConfirm}
                  color="error"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {loading ? (
            <Loading />
          ) : (
            <TopTenVisuals
              interactions={interactions}
              admin={admin}
              setExportRef={setExportRef}
              fromDate={fromDate}
              toDate={toDate}
            />
          )}
        </div>
      </div>
    );
  };

  // Render the DOM
  return getElements();
}

// Top Ten visuals component - displays interaction data retrieved from the analytics API
function TopTenVisuals(props) {
  // Destruct props object
  const { interactions, admin, setExportRef, fromDate, toDate } = props;
  // Component container and subsequent page height refs
  const containerRef = useRef();
  const pageHeightRef = useRef();
  // Report container ref
  const exportRef = useRef();
  // Transformed data sets
  const [dailyData, setDailyData] = useState([]);
  // const [hourlyData, setHourlyData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [gestureData, setGestureData] = useState([]);
  // const [radarDomain, setRadarDomain] = useState(0);
  const [assetData, setAssetData] = useState([]);

  useEffect(() => {
    // Set the container reference
    setExportRef(exportRef);
    // Reset page height
    pageHeightRef.current = containerRef?.current?.clientHeight || 0;

    // Transform the data for the visuals
    const data = (interactions || []).map((v) => {
      // Get the frame number and total number of gestures
      const frame = ("" + v.FriendlyName).toUpperCase();
      const numGestures =
        v.GestureUp + v.GestureDown + v.GestureLeft + v.GestureRight;

      // Add data fields to the existing object
      const transformed = {
        ...v,
        SubmittedUnix: dayjs(v.SubmittedAt).unix(),
        SubmittedDayUnix: dayjs(
          dayjs(v.SubmittedAt).format("YYYY-MM-DD")
        ).unix(),
        SubmittedHourUnix: dayjs(
          dayjs(v.SubmittedAt).format("YYYY-MM-DD HH:00:00")
        ).unix(),
        Interactions: numGestures,
        Frame1: frame === "SENSOR1" ? numGestures : 0,
        Frame2: frame === "SENSOR2" ? numGestures : 0,
        Frame3: frame === "SENSOR3" ? numGestures : 0,
        Frame4: frame === "SENSOR4" ? numGestures : 0,
        Frame5: frame === "SENSOR5" ? numGestures : 0,

        // Deleted flags
        Deleted: v.DeletedAt > 0 ? numGestures : 0,
      };

      return transformed;
    });

    // Submission maps
    var dayMap = {};
    var hourMap = {};
    var gestureMap = {};
    var deletedGestureMap = {};
    var siteMap = {};
    var deletedSiteMap = {};
    var assetMap = {};
    var deletedAssetMap = {};

    // Format the records into a hash map by timestamp and site ID
    data.forEach((v) => {
      // Map the day
      const existingDay = dayMap[v.SubmittedDayUnix];
      dayMap[v.SubmittedDayUnix] = {
        ...existingDay,
        SubmittedUnix: v.SubmittedUnix,
        Interactions: (existingDay?.Interactions || 0) + v.Interactions,
        Frame1: (existingDay?.Frame1 || 0) + v.Frame1,
        Frame2: (existingDay?.Frame2 || 0) + v.Frame2,
        Frame3: (existingDay?.Frame3 || 0) + v.Frame3,
        Frame4: (existingDay?.Frame4 || 0) + v.Frame4,
        Frame5: (existingDay?.Frame5 || 0) + v.Frame5,

        // Deleted flags
        Deleted: (existingDay?.Deleted || 0) + v.Deleted,
      };

      // Map the hour
      const existingHour = hourMap[v.SubmittedHourUnix];
      hourMap[v.SubmittedHourUnix] = {
        ...existingHour,
        SubmittedUnix: v.SubmittedUnix,
        Interactions: (existingHour?.Interactions || 0) + v.Interactions,
        Frame1: (existingHour?.Frame1 || 0) + v.Frame1,
        Frame2: (existingHour?.Frame2 || 0) + v.Frame2,
        Frame3: (existingHour?.Frame3 || 0) + v.Frame3,
        Frame4: (existingHour?.Frame4 || 0) + v.Frame4,
        Frame5: (existingHour?.Frame5 || 0) + v.Frame5,
      };

      // Gesture totals
      gestureMap["Up"] = (gestureMap["Up"] || 0) + v.GestureUp;
      gestureMap["Right"] = (gestureMap["Right"] || 0) + v.GestureRight;
      gestureMap["Down"] = (gestureMap["Down"] || 0) + v.GestureDown;
      gestureMap["Left"] = (gestureMap["Left"] || 0) + v.GestureLeft;
      // Deleted gesture totals
      if (v.DeletedAt > 0) {
        deletedGestureMap["Up"] = (deletedGestureMap["Up"] || 0) + v.GestureUp;
        deletedGestureMap["Right"] =
          (deletedGestureMap["Right"] || 0) + v.GestureRight;
        deletedGestureMap["Down"] =
          (deletedGestureMap["Down"] || 0) + v.GestureDown;
        deletedGestureMap["Left"] =
          (deletedGestureMap["Left"] || 0) + v.GestureLeft;
      }

      // Site totals
      siteMap[v.SiteName] = (siteMap[v.SiteName] || 0) + v.Interactions;
      // Deleted site totals
      if (v.DeletedAt > 0)
        deletedSiteMap[v.SiteName] =
          (deletedSiteMap[v.SiteName] || 0) + v.Interactions;

      // Asset totals, exit if no assets tracked
      if (v?.Assets.length < 1) return;

      // Add interactions for this set of assets
      assetMap[v.Assets[0]] = (assetMap[v.Assets[0]] || 0) + v.Interactions;

      // Factor in deleted interactions
      if (v.DeletedAt > 0)
        deletedAssetMap[v.Assets[0]] =
          (deletedAssetMap[v.Assets[0]] || 0) + v.Interactions;
    });

    // List of time-relevant records
    var dayRecords = [];
    var hourRecords = [];
    var gestureRecords = [];
    var siteRecords = [];
    var assetRecords = [];

    // Get the daily records as an array for display
    for (var day in dayMap) {
      dayRecords.push(dayMap[day]);
    }

    // Get the hourly records as an array for display
    for (var hour in hourMap) {
      hourRecords.push(hourMap[hour]);
    }

    // Get the gesture records as an array for display, and calculate the radar domain
    var gestureMax = 0;
    for (var gesture in gestureMap) {
      gestureMax = Math.max(gestureMax, gestureMap[gesture]);
      gestureRecords.push({
        name: gesture,
        value: gestureMap[gesture],
        deleted: deletedGestureMap[gesture] || 0,
      });
    }

    // Get the site records as an array for display
    var totalDeleted = 0;
    for (var site in siteMap) {
      siteRecords.push({
        name: site,
        value: siteMap[site],
        deleted: deletedSiteMap[site],
      });
      totalDeleted += deletedSiteMap[site];
    }

    // Append the deletion data
    if (admin)
      siteRecords.push({
        name: "Deleted",
        value: totalDeleted,
        deleted: 0,
      });

    // Get the asset records as an array for display
    totalDeleted = 0;
    for (var asset in assetMap) {
      assetRecords.push({
        name: asset,
        value: assetMap[asset],
        deleted: deletedAssetMap[asset],
      });
      totalDeleted += deletedAssetMap[asset];
    }

    // Append the deletion data
    if (admin)
      assetRecords.push({
        name: "Deleted",
        value: totalDeleted,
        deleted: 0,
      });

    // Sort by interactions for the tree map
    assetRecords.sort((a, b) => b.value - a.value);

    // Set data states
    setDailyData(dayRecords);
    setGestureData(gestureRecords);
    // setRadarDomain(gestureMax);
    setSiteData(siteRecords);
    setAssetData(assetRecords);
  }, [interactions, admin, setExportRef]);

  // Get the top performing day
  const getTimeSeries = (data, siteNames) => {
    // Input copy, exiting if malformed
    const interactions = (!!siteNames) ? data.flatMap(v => siteNames.includes(v.SiteName) ? [v] : []) : [...data];
    if (!interactions || !interactions.length)
      return;

    // Resulting time series data
    var result = [];

    // Loop through and add each successive day's interactions
    interactions.forEach((v, i) => {

      // Store a shallow copy of the interaction
      var newItem = { ...v };

      // Add previous value if applicable
      if (i > 0) {
        newItem.Interactions += result[result.length - 1]?.Interactions || 0;
        if (admin) newItem.Deleted += result[result.length - 1]?.Deleted || 0;
      }

      // Add to the time series
      result.push(newItem);
    });

    // Return the result
    return result;

  };

  // Get the top performing day
  const getTopDay = (data) => {
    // Input copy, exiting if malformed
    const interactions = [...data];
    if (!interactions || !interactions.length)
      return;

    // Placeholders for the top value and day
    var topDay = "";
    var topValue = -1;

    // Loop through and evaluate each day's interactions
    for (var i = 0; i < interactions.length; i++) {
      // Reference the current entry and skip if malformed
      const entry = interactions[i];
      if (!entry || !entry.Interactions || !entry.SubmittedUnix)
        continue;

      // Update the result if a new max is found
      if (entry.Interactions > topValue) {
        topDay = dayjs.unix(entry.SubmittedUnix).format("D MMM 'YY") || "NA";
        topValue = entry.Interactions || -1;
        continue;
      }

      // Append the result if a tie is found
      if (entry.Interactions === topValue)
        topDay += ` / ${dayjs.unix(entry.SubmittedUnix).format("D MMM 'YY") || "NA"}`;
    }

    // Return the formatted date string
    return topDay;

  };

  return interactions?.length > 0 ? (
    <>
      <div className="container scroll-y" style={{ zIndex: 1 }} ref={containerRef}>
        <div className="dashboard-container">
          <div className="flex-column shadow-divider" style={{ height: pageHeightRef.current || 0 }}>
            <div className="flex-1 shadow-divider">
              <div className="container flex-column">
                <div className="flex-2">
                  <p style={{ fontSize: "1.25em", fontWeight: "normal", textAlign: "left" }}>Interactions From {dayjs(fromDate).format("D MMMM YYYY")} To {dayjs(toDate).format("DD MMMM YYYY")}</p>
                  <p style={{ fontSize: "0.9em", textAlign: "left", paddingLeft: "4px" }}>Across all sites and frames</p>
                </div>
                <div className="flex-3 flex-row">
                  <div className="flex-1"></div>
                  <div className="data-card flex-1 flex-column">
                    <div className="data-card-value flex-2">{admin ? (siteData || []).length - 1 : (siteData || []).length}</div>
                    <div className="data-card-label flex-1">Total Endpoints</div>
                  </div>
                  <div className="flex-1"></div>
                  <div className="data-card flex-1 flex-column">
                    <div className="data-card-value flex-2">{siteData.reduce((total, v) => (admin && v.name.toLowerCase() === "deleted") ? total : (total + (v.value || 0)), 0)}</div>
                    <div className="data-card-label flex-1">Total Gestures</div>
                  </div>
                  <div className="flex-1"></div>
                  <div className="data-card flex-1 flex-column">
                    <div className="data-card-value flex-2">{getTopDay(dailyData)}</div>
                    <div className="data-card-label flex-1">Highest Traffic Date</div>
                  </div>
                  <div className="flex-1"></div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="container flex-column">
                <div className="flex-2">
                  <p style={{ fontSize: "1em", textAlign: "left", paddingLeft: "10px", paddingTop: "10px" }}>Daily Interactions</p>
                </div>
                <div className="flex-10 flex-row flex-cc">
                  <ResponsiveContainer>
                    <ComposedChart data={dailyData}>
                      <Legend verticalAlign="top" align="center" layout="horizontal" />
                      <CartesianGrid strokeDasharray="4" opacity={0.25} />
                      <Tooltip
                        content={<CustomGraphToolTip />}
                        cursor={{ fill: "transparent" }}
                      />
                      <XAxis
                        dataKey="SubmittedUnix"
                        includeHidden
                        tickFormatter={(v) => dayjs.unix(v).format("D/M")}
                        tick={dailyData?.length < 30}
                      />
                      <YAxis dataKey="Interactions" />

                      <Area
                        type="monotone"
                        dataKey="Interactions"
                        fill="#34B3F1"
                        stroke="#34B3F1"
                      />
                      {admin && (
                        <Area
                          type="monotone"
                          dataKey="Deleted"
                          fill="#ff4d4d"
                          stroke="#ff4d4d"
                        />
                      )}
                      <Bar dataKey="Frame1" fill="#e986ff" />
                      <Bar dataKey="Frame2" fill="#86ffad" />
                      <Bar dataKey="Frame3" fill="#ffe986" />
                      <Bar dataKey="Frame4" fill="#b9c6ff" />
                      <Bar dataKey="Frame5" fill="#ffb9c6" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-column shadow-divider" style={{ height: pageHeightRef.current || 0 }}>

            <div className="flex-1 shadow-divider">
              <div className="container flex-column">
                <div className="flex-2">
                  <p style={{ fontSize: "1em", textAlign: "left", paddingLeft: "10px", paddingTop: "10px" }}>Interactions Over Time</p>
                </div>
                <div className="flex-10 flex-row flex-cc">
                  <ResponsiveContainer>
                    <ComposedChart data={getTimeSeries(dailyData)}>
                      <CartesianGrid strokeDasharray="4" opacity={0.25} />
                      <Tooltip
                        content={<CustomGraphToolTip />}
                        cursor={{ fill: "transparent" }}
                      />
                      <XAxis
                        dataKey="SubmittedUnix"
                        includeHidden
                        tickFormatter={(v) => dayjs.unix(v).format("D/M")}
                        tick={dailyData?.length < 30}
                      />
                      <YAxis dataKey="Interactions" />

                      <Area
                        type="monotone"
                        dataKey="Interactions"
                        fill="#34B3F1"
                        stroke="#34B3F1"
                      />
                      {admin && (
                        <Area
                          type="monotone"
                          dataKey="Deleted"
                          fill="#ff4d4d"
                          stroke="#ff4d4d"
                        />
                      )}
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className="flex-1 flex-column">
              <div className="flex-2">
                <p style={{ fontSize: "1em", textAlign: "left", paddingLeft: "10px", paddingTop: "10px" }}>Gesture Distribution</p>
              </div>
              <div className="flex-10 flex-row flex-cc">
                <div className="flex-1"></div>
                <ResponsiveContainer className="flex-2">
                  <PieChart>
                    <Tooltip
                      content={<CustomGraphToolTip />}
                      cursor={{ fill: "transparent" }}
                    />
                    <Pie
                      data={gestureData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      fillOpacity={0.75}
                      outerRadius={"65%"}
                      labelLine={false}
                      label={pieChartInnerLabel}
                    >
                      {gestureData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={"#34B3F1"}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <ResponsiveContainer className="flex-3" height={"90%"}>
                  <BarChart data={gestureData} layout="vertical">
                    <Tooltip
                      content={<CustomGraphToolTip />}
                      cursor={{ fill: "transparent" }}
                    />
                    <XAxis type="number" tick={{ fill: '#fff' }} stroke="#fff" />
                    <YAxis
                      dataKey="name"
                      type="category"
                      stroke="#fff"
                    />
                    <Bar
                      name="Interactions"
                      dataKey="value"
                      fill="#34B3F1"
                      fillOpacity={0.75}
                      label={<CustomBarChartLabel />}
                      maxBarSize={30}
                      stroke="#fff"
                    />
                  </BarChart>
                </ResponsiveContainer>
                <div className="flex-1"></div>
              </div>
            </div>
          </div>

          <div className="flex-column shadow-divider" style={{ height: 1 * pageHeightRef.current || 0 }}>



            <div className="flex-1">
              <p style={{ fontSize: "1em", textAlign: "left", paddingLeft: "10px", paddingTop: "10px" }}>Interactions by Endpoint</p>
            </div>

            <ResponsiveContainer className="flex-9">
              <BarChart data={siteData.slice(0, 20)} layout="vertical">
                <Tooltip
                  content={<CustomGraphToolTip />}
                  cursor={{ fill: "transparent" }}
                />
                <XAxis type="number" tick={{ fill: '#fff' }} stroke="#fff" />
                <YAxis
                  dataKey="name"
                  type="category"
                  width={175}
                  stroke="#fff"
                />
                <Bar
                  name="Interactions"
                  dataKey="value"
                  fill="#34B3F1"
                  fillOpacity={0.75}
                  label={<CustomBarChartLabel />}
                  maxBarSize={30}
                  stroke="#fff"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="flex-column shadow-divider" style={{ height: 1 * pageHeightRef.current || 0 }}>

            <div className="flex-1">
              <p style={{ fontSize: "1em", textAlign: "left", paddingLeft: "10px", paddingTop: "10px" }}>Interaction by Asset</p>
            </div>

            <ResponsiveContainer className="flex-9">
              <BarChart data={assetData.slice(0, 20)} layout="vertical">
                <Tooltip
                  content={<CustomGraphToolTip />}
                  cursor={{ fill: "transparent" }}
                />
                <XAxis type="number" tick={{ fill: '#fff' }} stroke="#fff" />
                <YAxis
                  dataKey="name"
                  type="category"
                  width={175}
                  stroke="#fff"
                />
                <Bar
                  name="Interactions"
                  dataKey="value"
                  fill="#34B3F1"
                  fillOpacity={0.75}
                  label={<CustomBarChartLabel />}
                  maxBarSize={30}
                  stroke="#fff"
                />
              </BarChart>
            </ResponsiveContainer>

          </div>
        </div>
      </div>

      <div id="report" className="scroll-y">
        <div className="dashboard-container" ref={exportRef}>
          <div className="flex-column shadow-divider" style={{ height: pageHeightRef.current || 0 }}>
            <div className="flex-1 shadow-divider">
              <div className="container flex-column">
                <div className="flex-2">
                  <p style={{ fontSize: "1.25em", fontWeight: "normal", textAlign: "left" }}>Interactions From {dayjs(fromDate).format("D MMMM YYYY")} To {dayjs(toDate).format("DD MMMM YYYY")}</p>
                  <p style={{ fontSize: "0.9em", textAlign: "left", paddingLeft: "4px" }}>Across all sites and frames</p>
                </div>
                <div className="flex-3 flex-row">
                  <div className="flex-1"></div>
                  <div className="data-card flex-1 flex-column">
                    <div className="data-card-value flex-2">{admin ? (siteData || []).length - 1 : (siteData || []).length}</div>
                    <div className="data-card-label flex-1">Total Endpoints</div>
                  </div>
                  <div className="flex-1"></div>
                  <div className="data-card flex-1 flex-column">
                    <div className="data-card-value flex-2">{siteData.reduce((total, v) => (admin && v.name.toLowerCase() === "deleted") ? total : (total + (v.value || 0)), 0)}</div>
                    <div className="data-card-label flex-1">Total Gestures</div>
                  </div>
                  <div className="flex-1"></div>
                  <div className="data-card flex-1 flex-column">
                    <div className="data-card-value flex-2">{getTopDay(dailyData)}</div>
                    <div className="data-card-label flex-1">Highest Traffic Date</div>
                  </div>
                  <div className="flex-1"></div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="container flex-column">
                <div className="flex-2">
                  <p style={{ fontSize: "1em", textAlign: "left", paddingLeft: "10px", paddingTop: "10px" }}>Daily Interactions</p>
                </div>
                <div className="flex-10 flex-row flex-cc">
                  <ResponsiveContainer>
                    <ComposedChart data={dailyData}>
                      <Legend verticalAlign="top" align="center" layout="horizontal" />
                      <CartesianGrid strokeDasharray="4" opacity={0.25} />
                      <Tooltip
                        content={<CustomGraphToolTip />}
                        cursor={{ fill: "transparent" }}
                      />
                      <XAxis
                        dataKey="SubmittedUnix"
                        includeHidden
                        tickFormatter={(v) => dayjs.unix(v).format("D/M")}
                        tick={dailyData?.length < 30}
                      />
                      <YAxis dataKey="Interactions" />

                      <Area
                        type="monotone"
                        dataKey="Interactions"
                        fill="#34B3F1"
                        stroke="#34B3F1"
                      />
                      {admin && (
                        <Area
                          type="monotone"
                          dataKey="Deleted"
                          fill="#ff4d4d"
                          stroke="#ff4d4d"
                        />
                      )}
                      <Bar dataKey="Frame1" fill="#e986ff" />
                      <Bar dataKey="Frame2" fill="#86ffad" />
                      <Bar dataKey="Frame3" fill="#ffe986" />
                      <Bar dataKey="Frame4" fill="#b9c6ff" />
                      <Bar dataKey="Frame5" fill="#ffb9c6" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-column shadow-divider" style={{ height: pageHeightRef.current || 0 }}>

            <div className="flex-1 shadow-divider">
              <div className="container flex-column">
                <div className="flex-2">
                  <p style={{ fontSize: "1em", textAlign: "left", paddingLeft: "10px", paddingTop: "10px" }}>Interactions Over Time</p>
                </div>
                <div className="flex-10 flex-row flex-cc">
                  <ResponsiveContainer>
                    <ComposedChart data={getTimeSeries(dailyData)}>
                      <CartesianGrid strokeDasharray="4" opacity={0.25} />
                      <Tooltip
                        content={<CustomGraphToolTip />}
                        cursor={{ fill: "transparent" }}
                      />
                      <XAxis
                        dataKey="SubmittedUnix"
                        includeHidden
                        tickFormatter={(v) => dayjs.unix(v).format("D/M")}
                        tick={dailyData?.length < 30}
                      />
                      <YAxis dataKey="Interactions" />

                      <Area
                        type="monotone"
                        dataKey="Interactions"
                        fill="#34B3F1"
                        stroke="#34B3F1"
                      />
                      {admin && (
                        <Area
                          type="monotone"
                          dataKey="Deleted"
                          fill="#ff4d4d"
                          stroke="#ff4d4d"
                        />
                      )}
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className="flex-1 flex-column">
              <div className="flex-2">
                <p style={{ fontSize: "1em", textAlign: "left", paddingLeft: "10px", paddingTop: "10px" }}>Gesture Distribution</p>
              </div>
              <div className="flex-10 flex-row flex-cc">
                <div className="flex-1"></div>
                <ResponsiveContainer className="flex-2">
                  <PieChart>
                    <Tooltip
                      content={<CustomGraphToolTip />}
                      cursor={{ fill: "transparent" }}
                    />
                    <Pie
                      data={gestureData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      fillOpacity={0.75}
                      outerRadius={"65%"}
                      labelLine={false}
                      label={pieChartInnerLabel}
                    >
                      {gestureData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={"#34B3F1"}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <ResponsiveContainer className="flex-3" height={"90%"}>
                  <BarChart data={gestureData} layout="vertical">
                    <Tooltip
                      content={<CustomGraphToolTip />}
                      cursor={{ fill: "transparent" }}
                    />
                    <XAxis type="number" tick={{ fill: '#fff' }} stroke="#fff" />
                    <YAxis
                      dataKey="name"
                      type="category"
                      stroke="#fff"
                    />
                    <Bar
                      name="Interactions"
                      dataKey="value"
                      fill="#34B3F1"
                      fillOpacity={0.75}
                      label={<CustomBarChartLabel />}
                      maxBarSize={30}
                      stroke="#fff"
                    />
                  </BarChart>
                </ResponsiveContainer>
                <div className="flex-1"></div>
              </div>
            </div>
          </div>

          <div className="flex-column shadow-divider" style={{ height: 1 * pageHeightRef.current || 0 }}>

            <div className="flex-1">
              <p style={{ fontSize: "1em", textAlign: "left", paddingLeft: "10px", paddingTop: "10px" }}>Interactions by Endpoint</p>
            </div>

            <ResponsiveContainer className="flex-9">
              <BarChart data={siteData.slice(0, 20)} layout="vertical">
                <Tooltip
                  content={<CustomGraphToolTip />}
                  cursor={{ fill: "transparent" }}
                />
                <XAxis type="number" tick={{ fill: '#fff' }} stroke="#fff" />
                <YAxis
                  dataKey="name"
                  type="category"
                  width={175}
                  stroke="#fff"
                />
                <Bar
                  name="Interactions"
                  dataKey="value"
                  fill="#34B3F1"
                  fillOpacity={0.75}
                  label={<CustomBarChartLabel />}
                  maxBarSize={30}
                  stroke="#fff"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="flex-column shadow-divider" style={{ height: 1 * pageHeightRef.current || 0 }}>

            <div className="flex-1">
              <p style={{ fontSize: "1em", textAlign: "left", paddingLeft: "10px", paddingTop: "10px" }}>Interaction by Asset</p>
            </div>

            <ResponsiveContainer className="flex-9">
              <BarChart data={assetData.slice(0, 20)} layout="vertical">
                <Tooltip
                  content={<CustomGraphToolTip />}
                  cursor={{ fill: "transparent" }}
                />
                <XAxis type="number" tick={{ fill: '#fff' }} stroke="#fff" />
                <YAxis
                  dataKey="name"
                  type="category"
                  width={175}
                  stroke="#fff"
                />
                <Bar
                  name="Interactions"
                  dataKey="value"
                  fill="#34B3F1"
                  fillOpacity={0.75}
                  label={<CustomBarChartLabel />}
                  maxBarSize={30}
                  stroke="#fff"
                />
              </BarChart>
            </ResponsiveContainer>

          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="container flex-row no-data">
      <h3>No data</h3>
    </div>
  );
}

// RAD helper
const RADIAN = Math.PI / 180;
// Custom pie chart label
const pieChartInnerLabel = (v) => {
  const { cx, cy, midAngle, outerRadius, name, percent, innerRadius } = v;

  // Calculate the radius and X,Y coords
  const x = cx + outerRadius * 1.1 * Math.cos(-midAngle * RADIAN);
  const y = cy + outerRadius * 1.1 * Math.sin(-midAngle * RADIAN);

  // Calculate the radius and X,Y coords for the % value
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x1 = cx + radius * Math.cos(-midAngle * RADIAN);
  const y1 = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <Text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {name}
      </Text>
      <Text
        x={x1}
        y={y1}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </Text>
    </>
  );
};

// Custom pie / radar graph tooltip
const CustomGraphToolTip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <span
          key={uuidv4()}
          className="label"
        >{dayjs.unix(label).isValid() && `Date: ${dayjs.unix(label).format("DD MMM YYYY")}`}</span>
        {payload.map((v) => (
          <span
            key={uuidv4()}
            className="label"
          >{`${v?.name}: ${v?.value}`}</span>
        ))}
      </div>
    );
  }

  return null;
};

// Custom bar chart label
const CustomBarChartLabel = ({ x, y, fill, value, width, height }) => {
  return (
    <Text
      x={width}
      y={y}
      dx={x + 5}
      dy={5 + height / 2}
      fontSize="18"
      fill={"#fff"}
      textAnchor="start"
    >
      {value}
    </Text>
  );
};
